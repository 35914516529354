// @vendors
const get = require("lodash/object/get");
const { isEmpty } = require("lodash");
const tags = require("okTagging/tagging.json");

// @core
const apiURLBuilder = require("core/apiURLBuilder");
const { getLanguage } = require("core/i18n").i18n;

// @utilities
const { APIGet, APIPost } = require("utilities/APIRequestHelper");
const { isPrintOfficeMode } = require("utilities/contactCenter/printerHelper");
const {
    PROC_SIGNATURE_PATTERN,
    PROC_RESTART,
    PROC_START,
    PROC_FAIL,
    PROC_END
} = require("utilities/tagging");
const { changeBiocatchContext } = require("utilities/biocatch");

// @actions
const { outdateAndResetReducers } = require("actions/projectWideActions");
const printerActions = require("actions/contactCenter/printer");

// @constants
const actionTypes = require("constants/actionTypes");
const { MULTIPLE_OPERATIONS } = require("constants/operationTypes");
const biocatch = require("constants/biocatch");
const { PUSH_NOTIFICATION } = require('constants/index');
const { taggingEventHandled } = require("../utilities/taggingEventHandled");

const requestConfiguration = params => ({
    type: actionTypes.SIGNATURE_PATTERN_CONFIG_REQUEST,
    payload: {
        operationsParams: params
    }
});

const restartRequestConfiguration = reason => ({
    type: actionTypes.SIGNATURE_PATTERN_CONFIG_RESTART_REQUEST,
    payload: {
        reasonForRestart: reason || ""
    }
});

const requestConfigurationSuccess = (response, id) => ({
    type: actionTypes.SIGNATURE_PATTERN_CONFIG_REQUEST_SUCCESS,
    payload: {
        allVerificationDone: get(response, "allVerificationDone", false),
        token: get(response, "dataToken", ""),
        requiredSMS: get(response, "otpChallenge", false),
        requiredDigits: get(response, "positions", null),
        id
    }
});

const requestConfigurationFailure = response => ({
    type: actionTypes.SIGNATURE_PATTERN_CONFIG_REQUEST_FAILURE,
    payload: {
        message: response
    }
});

const processRequestSignaturePassCodeSuccess = response => ({
    type: actionTypes.SIGNATURE_PATTERN_PASS_CODE_VALIDATION_SUCCESS,
    payload: {
        allVerificationDone: get(response, "allVerificationDone", false),
        token: get(response, "dataToken", ""),
        requiredSMS: get(response, "otpChallenge", false),
        requiredDigits: get(response, "positions", null)
    }
});

const requestSignaturePassCode = response => ({
    type: actionTypes.SIGNATURE_PATTERN_PASS_CODE_VALIDATION,
    payload: {
        message: response
    }
});

const validateDigitInput = data => ({
    type: actionTypes.SIGNATURE_PATTERN_VALIDATE_DIGIT,
    payload: {
        newDigit: data.newDigit,
        position: data.position
    }
});

const requestProcessRequestSMS = () => ({
    type: actionTypes.SIGNATURE_PATTERN_SMS_VALIDATION
});

const requestProcessRequestSMSSuccess = response => ({
    type: actionTypes.SIGNATURE_PATTERN_SMS_VALIDATION_SUCCESS,
    payload: {
        allVerificationDone: get(response, "allVerificationDone", false),
        token: get(response, "dataToken", "")
    }
});

const requestProcessRequestSMSFailure = (error, text, errorCode) => ({
    type: actionTypes.SIGNATURE_PATTERN_SMS_VALIDATION_FAILURE,
    payload: {
        error,
        errorDescription: text,
        errorCode: errorCode,
    }
});

const processRequestSignaturePassCodeFailure = response => ({
    type: actionTypes.SIGNATURE_PATTERN_PASS_CODE_VALIDATION_FAILURE,
    payload: {
        message: response
    }
});

const reset = () => ({ type: actionTypes.SIGNATURE_PATTERN_RESET });

const handleOnChangeSMS = evt => {
    const value = evt.target.value || "";
    return {
        type: actionTypes.SIGNATURE_PATTERN_UPDATE_INPUT_SMS,
        payload: {
            value: value.trim().toUpperCase()
        }
    };
};

const showWarningSMS = () => ({
    type: actionTypes.SIGNATURE_PATTERN_SHOW_WARNING_SMS
});

const showRequired = () => ({
    type: actionTypes.SIGNATURE_PATTERN_SHOW_REQUIRED
});

const callbackCalled = () => ({
    type: actionTypes.SIGNATURE_PATTERN_CALLBACK_CALLED
});

const validateVirtualOfficeRequest = () => ({
    type: actionTypes.VALIDATE_USER_VIRTUAL_OFFICES_REQUEST
});

const validateVirtualOfficeSuccess = response => ({
    type: actionTypes.VALIDATE_USER_VIRTUAL_OFFICES_SUCCESS,
    payload: response
});

const validateVirtualOfficeFailure = () => ({
    type: actionTypes.VALIDATE_USER_VIRTUAL_OFFICES_FAILURE
});

const requestTrustedDeviceInfoRequest = () => ({
    type: actionTypes.SIGNATURE_PATTERN_TRUSTED_DEVICE_REQUEST
})

const requestTrustedDeviceInfoSuccess = response => ({
    type: actionTypes.SIGNATURE_PATTERN_TRUSTED_DEVICE_SUCCESS,
    payload: response
});

const requestTrustedDeviceInfoFailure = () => ({
    type: actionTypes.SIGNATURE_PATTERN_TRUSTED_DEVICE_FAILURE
});

const showBiocatchModal = (bioCatchError) => ({
    type: actionTypes.SIGNATURE_PATTERN_BIOCATCH_EXCEPTION,
    payload: bioCatchError
})

const taggingErrorGenerate = (tagging, response) => {
    const errorTag = tagging.customCategoryError && {
        categoriaError: tagging.customCategoryError,
        descripcionError: response.error.description
    }
    return errorTag
}

const validateVirtualOffice = () => (dispatch, getState) => {
    const signaturePatternVirtualOffices = getState().signaturePatternVirtualOffices;
    if (signaturePatternVirtualOffices.get('isFetchingVirtualOffices') || signaturePatternVirtualOffices.get('virtualOfficeRequestSuccess')) {
        return Promise.resolve();
    }
    const query = {
        header: {
            version: 3
        }
    };
    dispatch(validateVirtualOfficeRequest());
    return APIGet(dispatch, apiURLBuilder.getURL('validateVirtualOffices', { feature: PUSH_NOTIFICATION }), query)
        .then(response => {
            dispatch(validateVirtualOfficeSuccess(response));
        })
        .catch(() => {
            dispatch(validateVirtualOfficeFailure());
        })
}

const requestTrustedDeviceInfo = () => dispatch => {
    const query = {
        query: {
            trusted: true
        }
    };
    dispatch(requestTrustedDeviceInfoRequest());
    return APIGet(dispatch, apiURLBuilder.getURL("devices"), query)
        .then(response => {
            dispatch(requestTrustedDeviceInfoSuccess(response));
        })
        .catch(() => {
            dispatch(requestTrustedDeviceInfoFailure());
        });
}

const processRequestConfiguration = (id, params = {}, tagging = {}) => dispatch => {
    // If params is an array and id is the multiple operations id, we adjust the request
    const query =
    Array.isArray(params) && id === MULTIPLE_OPERATIONS
    ? { requestMulti: params }
    : params;
    const language = getLanguage().toLowerCase();
    const amount = params && params.ammount ? params.ammount : null;

    dispatch(requestConfiguration(query));
    return APIPost(
        dispatch,
        apiURLBuilder.getURL("signaturePatternRequestConfig", { id, language }),
        {
            query,
            isFullResponse: true
        }
        )
    .then(fullResponse => {
        if (fullResponse.statusCode === biocatch.BIOCATCH_EXCEPTION_STATUS_CODE) {
            const biocarchError = fullResponse.body.bioCatchError || biocatch.NO_VALUE;
            dispatch(showBiocatchModal(biocarchError));
        } else {
            dispatch(requestConfigurationSuccess(fullResponse.body, id));
        }
            // Generate Analytic event
            const eventTag = tagging.customEventTag || tags[id];
            taggingEventHandled(PROC_SIGNATURE_PATTERN, PROC_START, eventTag, amount);
        })
        .catch(responseFailure => {
            dispatch(requestConfigurationFailure(responseFailure));
            // Generate Analytic event
            const eventTag = tagging.customEventTag || tags[id];
            const errorTag = taggingErrorGenerate(tagging, responseFailure)
            taggingEventHandled(PROC_SIGNATURE_PATTERN, PROC_FAIL, eventTag, amount, undefined, errorTag);
            throw responseFailure;
        });
};

const restartProcessRequestConfiguration = (
    id,
    params = {},
    reasonForRestart
) => (dispatch, getState) => {
    // (andres): So far, we haven't been able to make the service to resend the SMS work (pattern/challenge)
    // Since we don't have a flow that shows clave de firma and OTP, we will restart the process
    // when the user clicks on "resend".

    // If params is an array and id is the multiple operations id, we adjust the request
    let query =
        Array.isArray(params) && id === MULTIPLE_OPERATIONS
            ? { requestMulti: params }
            : params;
    const language = getLanguage().toLowerCase();
    const amount = params && params.ammount ? params.ammount : null;

    // when we call restartProcessRequestConfiguration after failure of verifyPositions/validtion of passcode
    // we need have operation params to successfully verify passcode that's why using stored query params which
    // was received during first request of Configuration
    if (isEmpty(query)) {
        query = getState()
            .signaturePattern.get("operationsParams")
            .toJS();
    }

    dispatch(restartRequestConfiguration(reasonForRestart));

    // Generate Analytic event
    taggingEventHandled(PROC_SIGNATURE_PATTERN, PROC_RESTART, tags[id], amount);

    return APIPost(
        dispatch,
        apiURLBuilder.getURL("signaturePatternRequestConfig", { id, language }),
        { query }
    )
        .then(responseSuccess =>
            dispatch(requestConfigurationSuccess(responseSuccess, id))
        )
        .catch(responseFailure => {
            dispatch(requestConfigurationFailure(responseFailure));
            // Generate Analytic event
            taggingEventHandled(PROC_SIGNATURE_PATTERN, PROC_FAIL, tags[id], amount);
            throw responseFailure;
        });
};

const processSignaturePassCode = (dispatch, getState, callback) => {
    const dataToken = getState().signaturePattern.get("token");
    const id = getState().signaturePattern.get("id");
    const withValidateButton = getState().signaturePattern.get(
        "withValidateButton"
    );
    const signaturePositionsValues = getState()
        .signaturePattern.get("consumerInputDigits")
        .join(" ");
    const params = __CONTACT_CENTER__
        ? getState()
              .signaturePattern.get("operationsParams")
              .toJS()
        : undefined;
    const requestData = {
        query: {
            signaturePositionsValues,
            dataToken
        }
    };
    dispatch(requestSignaturePassCode());
    const amount = params && params.ammount ? params.ammount : null;

    return APIPost(
        dispatch,
        apiURLBuilder.getURL("signaturePatternVerifyPositions"),
        requestData
    )
        .then(responseSuccess => {
            dispatch(processRequestSignaturePassCodeSuccess(responseSuccess));

            // Generate Analytic event
            taggingEventHandled(PROC_SIGNATURE_PATTERN, PROC_END, tags[id], amount);

            __CONTACT_CENTER__ && dispatch(outdateAndResetReducers());
            const allVerificationDone = get(
                responseSuccess,
                "allVerificationDone",
                false
            );
            if (allVerificationDone) {
                dispatch(printerActions.resetPrintedIndicators());
                const dataToken = get(responseSuccess, "dataToken", "");
                if (callback) {
                    dispatch(callbackCalled());
                    callback(dispatch, getState, dataToken);
                    return dataToken;
                } else if (!callback && withValidateButton) {
                    return;
                } else {
                    throw responseSuccess; // Not success really
                }
            }
        })
        .catch(responseFailure => {
            dispatch(processRequestSignaturePassCodeFailure(responseFailure));
            dispatch(restartProcessRequestConfiguration(id, params));

            // Generate Analytic event
            taggingEventHandled(PROC_SIGNATURE_PATTERN, PROC_FAIL, tags[id], amount);

            throw responseFailure;
        });
};

const processSignaturePassCodeWithoutCallback = () => (dispatch, getState) => {
    return processSignaturePassCode(dispatch, getState);
};

const processRequestSMS = (dispatch, getState, callback, tagging) => {
    const dataToken = getState().signaturePattern.get("token");
    const id = getState().signaturePattern.get("id");
    const signatureOTPValue = getState().signaturePattern.get("inputValueSMS");
    const requestData = {
        query: {
            signatureOTPValue,
            dataToken
        }
    };

    const params = getState().signaturePattern.get("operationsParams").toJS();
    const amount = params && params.ammount ? params.ammount : null;

    dispatch(requestProcessRequestSMS());
    return APIPost(
        dispatch,
        apiURLBuilder.getURL("signaturePatternVerifyOpt"),
        requestData
    )
        .then(responseSuccess => {
            dispatch(requestProcessRequestSMSSuccess(responseSuccess));
            __CONTACT_CENTER__ &&
                dispatch(printerActions.resetPrintedIndicators());
            dispatch(outdateAndResetReducers());
            const allVerificationDone = get(
                responseSuccess,
                "allVerificationDone",
                false
            );
            if (allVerificationDone) {
                // Generate Analytic event
                const eventTag = tagging.customEventTag || tags[id];
                taggingEventHandled(PROC_SIGNATURE_PATTERN, PROC_END, eventTag, amount);
                dispatch(callbackCalled());
                const dataToken = get(responseSuccess, "dataToken", "");
                if (callback) {
                    callback(dispatch, getState, dataToken);
                }
                return dataToken;
            } else {
                throw responseSuccess; // Not success really
            }
        })
        .catch(response => {
            if (!!response.error) {
                const errorCode = response.body
                    ? response.body.error
                    : '';
                const errorDescription = response.body
                ? response.body.errorDescription
                    ? response.body.errorDescription
                    : response.body.message
                : '';
                dispatch(
                    requestProcessRequestSMSFailure(response.error.description, errorDescription, errorCode)
                );
                // Generate Analytic event
                const eventTag = tagging.customEventTag || tags[id];
                const errorTag = taggingErrorGenerate(tagging, response)
                taggingEventHandled(PROC_SIGNATURE_PATTERN, PROC_FAIL, eventTag, amount, undefined, errorTag);
                changeBiocatchContext(biocatch.WRONG_CONFIRM_CODE);
            } else {
                throw response;
            }
        });
};

const setWithValidateButton = withValidateButton => ({
    type: actionTypes.SIGNATURE_PATTERN_SET_WITH_VALIDATE_BUTTON,
    withValidateButton
});

const validateAndExecute = (dispatch, getState, callback, officeSignature = false, tagging = {}) => {
    const signaturePattern = getState().signaturePattern;
    const withValidateButton = getState().signaturePattern.get(
        "withValidateButton"
    );

    if (!officeSignature && __CONTACT_CENTER__ && isPrintOfficeMode()) {
        const isShowingPreview = getState().printer.get("isShowingPreview");

        dispatch(printerActions.cleanErrorMessage());
        if (!isShowingPreview) {
            dispatch(printerActions.showPrintRequired());
            return Promise.reject(signaturePattern);
        } else if (
            isShowingPreview &&
            getState().printer.get("documentsPrintedAndSigned") !== "YES"
        ) {
            dispatch(printerActions.showPrintedAndSignedStatusRequired());
            return Promise.reject(signaturePattern);
        } else {
            dispatch(printerActions.printedAndSignedSuccess());
        }
    }

    if (
        signaturePattern.get("isFetchingSMSCode") ||
        signaturePattern.get("isFetchingSignaturePassCode")
    ) {
        return;
    }

    if (
        signaturePattern.get("isFetchingConfigSuccess") &&
        !signaturePattern.get("requiredDigits") &&
        !signaturePattern.get("requiredSMS")
    ) {
        dispatch(outdateAndResetReducers());

        dispatch(callbackCalled());
        callback(dispatch, getState, signaturePattern.get("token"));
        return Promise.resolve(signaturePattern.get("token"));
    }

    if (!signaturePattern.get("isFetchingConfigSuccess")) {
        //TODO: this should be changed
        return Promise.resolve();
    }
    if (!!signaturePattern.get("requiredDigits")) {
        // If asks for signature pass code
        if (!signaturePattern.get("requiredSMS")) {
            if (withValidateButton) {
                const token = signaturePattern.get("token");
                dispatch(callbackCalled());
                callback(dispatch, getState, token);
                return token;
            } else if (signaturePattern.get("consumerFinishInputDigits")) {
                return processSignaturePassCode(dispatch, getState, callback);
            } else {
                dispatch(showRequired()); //(juan) We should show another message here (signaturePattern-incompleteSignatureCode) instead of showRequired.
                return Promise.reject(signaturePattern);
            }
        } else {
            if (!signaturePattern.get("isFetchingSignaturePassCodeSuccess")) {
                // If I dont validate signature code
                dispatch(showRequired());
                return Promise.reject(signaturePattern);
            } else {
                if (!signaturePattern.get("isSMSValid")) {
                    // If sms it not valid
                    dispatch(showWarningSMS());
                    return Promise.reject(signaturePattern);
                } else {
                    // Calls sms validation, passing the callback from the original caller
                    return processRequestSMS(dispatch, getState, callback, tagging);
                }
            }
        }
    } else {
        // Only requires SMS
        if (!signaturePattern.get("isSMSValid")) {
            // If sms it not valid
            dispatch(showWarningSMS());
            return Promise.reject(signaturePattern);
        } else {
            // Calls sms validation, passing the callback from the original caller
            return processRequestSMS(dispatch, getState, callback, tagging);
        }
    }
};

const validateAndExecuteSagas = callback => (dispatch, getState) => {
    validateAndExecute(dispatch, getState, callback);
};

module.exports = {
    handleOnChangeSMS,
    processRequestConfiguration,
    processSignaturePassCodeWithoutCallback,
    requestTrustedDeviceInfo,
    reset,
    restartProcessRequestConfiguration,
    setWithValidateButton,
    showRequired,
    showWarningSMS,
    validateAndExecute,
    validateAndExecuteSagas,
    validateDigitInput,
    validateVirtualOffice
};
